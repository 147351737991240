import React from 'react'
import LightboxLayout from '../../components/lightboxLayout'

const Page = () => {
  return (
    <LightboxLayout>
      <h2>VENEERS</h2>
      <h3>What are they?</h3>
      <p>A veneer is a thin layer of tooth-coloured material that is put onto the front of the tooth to make it look better. This is done because the tooth might have been damaged by decay or an accident, or might be badly discoloured for some reason.</p>
      <h3>What will my dentist do?</h3>
      <p>Veneers are usually made out of porcelain by a dental technician. You will have to visit the dentist more than once for this type of veneer.</p>
      <p>Your dentist will check any fillings in the teeth first.</p>
      <p>A very small amount of natural tooth material needs to be removed -just enough to prevent the veneer making the tooth look or feel bulky. For this reason, it may not be necessary for the dentist to numb the tooth. Preparation time will depend  on how damaged  the tooth is and whether it needs to be built up with a filling first.</p>
      <p>The dentist will need to use a putty-like material to make a mould of your mouth (called an "impression"), so the dental technician can tell the shape and size of veneer to make for you.</p>
      <p>Your dentist will glue the veneer made by the technician  to the tooth when you next visit.</p>

      <p>Veneers sometimes come away from the tooth or break if the tooth is knocked or if you clench or grind your teeth. They can sometimes be glued back on, but will have to be replaced if they are damaged.</p>
      <p>Occasionally, sensivity or loss of vitality can occur.</p>
      <p>Some veneers are built up on the tooth directly using the same material that is used for white fillings.  The surface of the tooth roughened with a mild acid. Then white filling material is applied in layers until the teeth look right.</p>
      <h3>What  are  the benefits?</h3>
      <p>Veneers can greatly improve your appearance. They hide imperfections, and you lose very little natural tooth material.</p>
      <p>Veneers also protect teeth from any more damage. Tooth surface can be dissolved away by acid in the mouth (usually from too frequent consumption of certain kinds of food and drink); this can make teeth very sensitive to hot and cold. In this situation, veneers can protect the teeth.</p>
      <p>If the tooth is strong, a veneer is often a better  option  than a crown  for improving a tooth's appearance.</p>
    </LightboxLayout>
  )
}

export default Page